import { useAxiosPost } from "./api.js";


export const useTask = async () =>{

    
    const store = useTaskStore()
    const errorStore = useErrorStore()

    const createTask  = async (data) =>{
        try {
            const {data:task,success,message} = await useAxiosFormDataPost('task',data)
            errorStore.showError(message,true,'success')
            store.addTask(task)
            return task
        } catch (error) {
            console.log("Task Error : ",error)
            errorStore.showError(error.message,true,'error')
            throw error
        }
    }

    const getTasks = async (id) => {
        try {
            const {data:tasks,message} = await useAxiosGet(`task/${id}/all`)
            store.setTasks(tasks)
            return tasks
        } catch (error) {
            console.log("Task Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const getTask = async (id) => {
        try {
            const {data:task,message} = await useAxiosGet(`task/${id}`)
            return task
        } catch (error) {
            console.log("Task Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const updateTask = async (taskId,newData) => {
        try {
            const {data,message} = await useAxiosPatch(`task/${taskId}`,newData)
            const task = await getTask(taskId)
            store.updateTaskDetail(taskId,task)
            errorStore.showError(message,true,'success')
            return task
        } catch (error) {
            console.log("Task Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const deleteTask = async (id) => {
        try {
            const {data,message} = await useAxiosDelete(`task/${id}`)
            store.removeTask(id)
            errorStore.showError(message,true,'success')
            return
        } catch (error) {
            console.log("Task Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const deleteAttachment = async (taskId,attachmentId) => {
        try {
            const {data,message} = await useAxiosDelete(`task/${taskId}/attachment/${attachmentId}`)
            const task = await getTask(taskId)
            store.updateTaskDetail(taskId,task)
            errorStore.showError(message,true,'success')
            return
        } catch (error) {
            console.log("Task Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const uploadAttachments = async (taskId,formData) => {
        try {
            const {data:attachments,message} = await useAxiosFormDataPost(`task/${taskId}/attachment`,formData)
            errorStore.showError(message,true,'success')
            return attachments
        } catch (error) {
            console.log("Task Error : ",error)
            errorStore.showError(error.message,true,'error')
        }    
    }

    const getDownloadLink = async (bucket,key) =>{
        const url = await useAxiosPost('download',{
            bucket:bucket,
            key:key
        })
        return url
    }

    // Comment

    const postComment = async (taskId,commentMessage) => {
        try {
            const {data:comment,message} = await useAxiosPost(`task/${taskId}/comment`,{
                message:commentMessage
            })
            errorStore.showError(message,true,'success')
            return comment
        } catch (error) {
            console.log("Task Comment Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const editComment = async (taskId,commentId,commentMessage) => {
        try {
            const {data:comment,message} = await useAxiosPatch(`task/${taskId}/comment/${commentId}`,{
                message:commentMessage
            })

            errorStore.showError(message,true,'success')
            return comment
        } catch (error) {
            console.log("Task Comment Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const deleteComment = async (taskId,commentId) => {
        try {
            const {data:comment,message} = await useAxiosDelete(`task/${taskId}/comment/${commentId}`)

            errorStore.showError(message,true,'success')
            return comment
        } catch (error) {
            console.log("Task Comment Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    return {
        createTask,
        getTasks,
        getTask,
        updateTask,
        deleteTask,
        getDownloadLink,
        deleteAttachment,
        uploadAttachments,
        postComment,
        editComment,
        deleteComment
    }
}