<template>
    <ModalsBase modalWidth="max-w-[700px]">
        <template #header>
            <div class="text-center mb-8">
                    <h2 class="mb-3">Create new task</h2>
                </div>
        </template>
        <template #body>
            <form action="" class="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4" @submit.prevent="createTaskBtn">
                    <div class="">
                        <Input id="name" label="Name" placeholder="Change wording on button..." v-model="taskDetail.name" :isRequired="true" :disabled="loading" />
                    </div>
                    <div class="">
                        <Input id="dueDate" label="Due Date" :min="currentDate" placeholder="12/05/2024" type="date" v-model="taskDetail.due_on"  :isRequired="true" :disabled="loading" />
                    </div>
                    <div class="">
                        <Input id="priority" label="Priority" icon="mynaui:chevron-down" type="select" v-model="taskDetail.priority" :isRequired="true" :disabled="loading"  >
                            <option value="low">Low Priority</option>
                            <option value="medium">Medium Priority</option>
                            <option value="high">High Priority</option>
                        </Input>
                    </div>
                    <div class="">
                        <Input id="assigned" label="Assign to" icon="mynaui:chevron-down" type="select" v-model="taskDetail.va_id" :isRequired="true" :disabled="loading">
                            <option  v-for="va in assigned_va" :key="va.id" :value="va.id" >
                                {{ va.first_name }} {{ va.last_name }}</option>
                        </Input>
                        <p class="text-red-700 text-xs pt-2 px-1" v-if="assigned_va.length <= 0">No virtual assistant assigned to this project</p>
                        
                    </div>
                    <div class="col-span-2">
                        <Input type="file" class="appearance-none" label="Attachments" id="upload" icon="mynaui:paperclip" :multiple="true" @files="fileHandling"  />
                    </div>
                    <div class="attachment-wrap flex items-center justify-between gap-3 py-3 px-3 border border-[#D1D1D1] rounded-md last:mb-0" v-for="(file,index) in files" :key="index">
                            <div class="flex items-center gap-3">
                                <div class="icon">
                                    <div class="w-11 h-11 flex items-center justify-center p-2 bg-[#F2F2F2] rounded-full">
                                        <img src="/images/069-jpg file.svg" class="h-full" alt="">
                                    </div>
                                </div>
                                <div class="attach-content min-w-0">
                                    <p class="mb-1 leading-none text-sm text-inactiveTabs truncate overflow-ellipsis">{{ truncateString(file.name,10,7,5) }}</p>
                                    <p class="mb-0 leading-none text-sm text-themegray">{{ formatFileSize(file.size) }}</p>
                                </div>
                            </div>
                            <button class="bg-black text-white text-[11px] leading-none px-3 py-2 rounded-full" @click="removeFile(index)">Remove</button>
                    </div>
                    <div class="col-span-2">
                        <Input label="Notes" placeholder="Enter notes" inputClassNew="resize-none" :multiline="true" v-model="taskDetail.note" :disabled="loading" />
                    </div>
                    <!-- <div class="">
                        <Input label="Comments" placeholder="Leave a comment" inputClassNew="resize-none" :multiline="true"  />
                    </div> -->
                    <div class="flex gap-4 mt-3 col-span-2">
                        <Button variant="outline" class="w-full" @click="closeModal()" :disabled="loading" >CANCEL</Button>
                        <Button class="w-full" type="submit" :loading="loading" :disabled="loading || assigned_va.length <= 0">CREATE</Button>
                    </div>
                    <p v-if="assigned_va.length <= 0" class=" mt-3 col-span-2 text-center text-secondary text-sm">NO Virtual Assistant Found! You can not create task.</p>
                </form>
        </template>
    </ModalsBase>
</template>

<script setup>
const emit = defineEmits(['close'])
const props = defineProps(['projectId'])
const { getProjectAssociates } = await useProject();


const closeModal = () => {
    emit('close', 'createTask', false)
}

// Task

const { createTask } = await useTask()
const errorStore = useErrorStore()
const taskDetail = reactive({
    va_id:'',
    name: '',
    due_on: '',
    priority: '',
    note: '',
})
const assigned_va = ref([])
const loading = ref(false)
const files = ref([])
const currentDate = new Date().toISOString().split('T')[0]

onMounted(async()=>{
    assigned_va.value = await getProjectAssociates(props.projectId)
})

const fileHandling = (f) =>{

if(f.length == 0){
    alert("Please select a file.")
    return
}

if(files.value.length > 4 || f.length > 4 || (files.value.length + f.length) > 4){
    alert("Only four images are allowed.")
    return
}

if(files.value.length == 0){
    files.value = Array.from(f)
    return
}

if(f.length > 1){
    files.value.push(...f)
}
else {
    files.value.push(f[0])
}

}

const createTaskBtn = async ()=>{
    
    try {

            loading.value = true
            let formData = new FormData()
        
            formData.append('project_id', props.projectId)
            formData.append('va_id', taskDetail.va_id)
            formData.append('name', taskDetail.name)
            formData.append('due_on', new Date(taskDetail.due_on).toISOString())
            formData.append('priority', taskDetail.priority)
            formData.append('note', taskDetail.note)
            formData.append('bucket', 'attachment')

            files.value.forEach(file => {
                    formData.append('files', file);
            });
            await createTask(formData)
            closeModal()
    } catch (error) {
        console.log('createTaskBtn',error)
    }
    finally{
        loading.value = false
    }
    
}

const truncateString = (str, firstCharCount = str.length, endCharCount = 0, dotCount = 3) => {
  if (str.length <= firstCharCount + endCharCount) {
    return str; // No truncation needed
  }

  const firstPortion = str.slice(0, firstCharCount);
  const endPortion = str.slice(-endCharCount);
  const dots = '.'.repeat(dotCount);

  return `${firstPortion}${dots}${endPortion}`;
}

const removeFile = (index) =>{
    files.value.splice(index,1)
}

function formatFileSize(bytes) {
  const MB = bytes / (1024 * 1024);
  if (MB < 1) {
    const KB = bytes / 1024;
    return KB.toFixed(2) + ' KB';
  }
  return MB.toFixed(2) + ' MB';
}

</script>