<template>
  <div>
    <div class="flex items-center justify-between mb-10">
      <div class="flex items-center gap-3">
        <NuxtLink prefetch to="/project">
          <Icon name="mynaui:chevron-left" role="button" size="20"/>
        </NuxtLink>
        <h3 class="font-semibold">Task</h3>
      </div>
      <Button icon="material-symbols:add" @click="toggleDialog('create', true)" v-if="user.profile.account_type =='client'">Add</Button>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
      <div class="todo">
        <div
          class="task-head flex justify-between items-center py-3 px-5 border border-1 border-todo rounded-xl bg-opacity-20 bg-todo mb-4">
          <span class="text-sm text-todo">To Do</span>
          <span class="text-xxs p-[5px] bg-todo text-white leading-none rounded-md min-w-5 text-center">
            {{ taskStore.todo.length  || 0}}</span>
        </div>
        <TaskCard v-for="task in taskStore.todo" :key="task.id" :data="task" @openDialog="toggleDialog" />
      </div>
      <div class="inprogress">
        <div
          class="task-head flex justify-between items-center py-3 px-5 border border-1 border-inprogress rounded-xl bg-opacity-10 bg-inprogress mb-4">
          <span class="text-sm text-inprogress">In Progress</span>
          <span class="text-xxs p-[5px] bg-inprogress text-white leading-none rounded-md min-w-5 text-center">
            {{ taskStore.inprogress.length  || 0}}
          </span>
        </div>
        <LazyTaskCard v-for="task in taskStore.inprogress" :key="task.id" :data="task"  @openDialog="toggleDialog" />
      </div>
      <div class="overdue">
        <div
          class="task-head flex justify-between items-center py-3 px-5 border border-1 border-overdue rounded-xl bg-opacity-10 bg-overdue mb-4">
          <span class="text-sm text-overdue">Overdue</span>
          <span class="text-xxs p-[5px] bg-overdue text-white leading-none rounded-md min-w-5 text-center">
            {{ taskStore.overdue.length  || 0}}
          </span>
        </div>
        <LazyTaskCard v-for="task in taskStore.overdue" :key="task.id" :data="task"  @openDialog="toggleDialog" />
      </div>
      <div class="complete">
        <div
          class="task-head flex justify-between items-center py-3 px-5 border border-1 border-complete rounded-xl bg-opacity-20 bg-complete mb-4">
          <span class="text-sm text-complete">Completed</span>
          <span class="text-xxs p-[5px] bg-complete text-white leading-none rounded-md min-w-5 text-center">
            {{ taskStore.completed.length  || 0}}
          </span>
        </div>
        <LazyTaskCard v-for="task in taskStore.completed" :key="task.id" :data="task"  @openDialog="toggleDialog" />
      </div>
    </div>
  </div>


  <LazyModalsEditTask :data="taskDetail" v-if="dialog.update" @close="toggleDialog('update', false)" />
  <LazyModalsDeleteTask :data="taskDetail" v-if="dialog.delete" @close="toggleDialog('delete', false)" />
  <LazyModalsTaskView :data="taskDetail" v-if="dialog.view" @close="toggleDialog('view', false)" />
  <ModalsCreateTask v-if="dialog.create" :projectId="projectId" @close="toggleDialog('create', false)" />


</template>

<script setup>
definePageMeta({
  layout: "dashboard-layout",
  middleware:'check-auth'
})

const route = useRoute()
const { getTasks } = await useTask()
const taskStore  = useTaskStore()
const projectId = route.params.id
const dialog = reactive({
  create: false,
  update: false,
  delete: false,
  view: false,
})

onMounted(async()=>{
  let tasksData = await getTasks(projectId)
})

const user = useUserStore()
const taskDetail = ref(null)

const toggleDialog = (modal, val,data={}) => {
  // taskStore.setTask(data)
  taskDetail.value = data
  if (modal == 'create') {
    dialog.create = val
  }
  else if (modal == 'update') {
    dialog.update = val
  }
  else if (modal == 'delete') {
    dialog.delete = val
  }
  else if (modal == 'view') {
    dialog.view = val
  }
}

const tasks = ref([
  {
    id: 1,
    task: "Change wording on button payment",
    image: "/images/user.png",
    priority: "high",
    date: "12 May, 2024",
  },
  {
    id: 2,
    task: "Change wording on button payment",
    image: "/images/user.png",
    priority: "low",
    date: "12 May, 2024",
  },
  {
    id: 3,
    task: "Change wording on button payment",
    image: "/images/user.png",
    priority: "medium",
    date: "12 May, 2024",
  }
])

</script>